<template>
  <div class="layout-person">
    <BlockHeader :links="headerLinks" />

    <!-- PersonHero -->
    <UtilWrapperLayout>
      <UtilGrid>
        <div class="layout-person__hero-person">
          <div
            class="layout-person__hero-person-back"
            @click="router.back()"
            @keypress="router.back()"
          >
            <IonIcon icon-name="chevron-left" />
            <p>
              {{ useStoryblokTranslation('general', 'back') }}
            </p>
          </div>
          <div class="layout-person__wrapper">
            <div class="layout-person__content">
              <div class="layout-person__content-name-wrapper">
                <div class="layout-person__content-type">
                  {{ extraData?.content?.author_type }}
                </div>
                <div class="layout-person__content-name">
                  {{ extraData?.content?.name }}
                </div>
                <div class="layout-person__content-title">
                  {{ extraData?.content?.position }}
                </div>
              </div>
              <div class="layout-person__share">
                <div
                  @click="share()"
                  @keypress="share()"
                >
                  <IonIcon
                    icon-name="share"
                  />
                </div>
                <UtilRouteLink
                  v-if="extraData?.content?.link?.cached_url"
                  :link="extraData.content.link.cached_url"
                >
                  <IonIcon icon-name="website" />
                </UtilRouteLink>
              </div>
            </div>
          </div>
          <div
            v-if="extraData?.content?.biographie?.content[0]?.content?.length > 0"
            class="layout-person__biografie"
          >
            <div class="layout-person__biografie-title">
              {{ useStoryblokTranslation('general', 'biography') }}
            </div>
            <AtomRichtext :html="richTextResolver.render(extraData.content.biographie)" />
          </div>
          <div class="layout-person__more-articles">
            <div class="layout-person__more-articles-title">
              {{ useStoryblokTranslation('general', 'article_of_author') }}
            </div>
            <div class="layout-person__more-articles-grid">
              <UtilRouteLink
                v-for="article in articlesOfAuthor"
                :key="article._uid"
                :link="article.default_full_slug"
                class="layout-person__more-article"
              >
                <AtomImage
                  v-if="article?.content?.image_video?.filename"
                  class="layout-person__more-article-image"
                  :image="article.content.image_video"
                  provider="storyblok"
                />
                <div class="layout-person__more-article-info">
                  {{ dayjs(article.first_published_at)?.locale(currentLocale).format('DD. MMMM YYYY') }}
                  <span> | </span>
                  {{ article.content?.department.name }}
                </div>
                <div class="layout-person__more-article-title">
                  {{ article.content?.title }}
                </div>
                <IonIcon
                  class="layout-person__more-article-icon"
                  icon-name="chevron-right"
                />
              </UtilRouteLink>
            </div>
            <AtomButton
              v-if="articlesOfAuthor?.length > 0"
              class="layout-person__more-button"
              :class="{ isHidden: articlesOfAuthor?.length < pageSize }"
              :text="useStoryblokTranslation('general', 'more_contributions')"
              @click="loadMore()"
            />
          </div>
        </div>
      </UtilGrid>
    </UtilWrapperLayout>

    <slot />
    <BlockServiceMenu
      v-if="serviceMenu?.show_service_navigation"
      :data="serviceMenu"
      :form-data="newsletterForm"
    />
    <BlockFooter :links="footerLinks" />
  </div>
</template>

<script setup>
const props = defineProps({
    headerLinks: {
        type: Array,
        default: () => [],
    },

    footerLinks: {
        type: Array,
        default: () => [],
    },

    serviceMenu: {
        type: Object,
        default: () => {},
    },

    newsletterForm: {
        type: Object,
        default: () => {},
    },

    extraData: {
        type: Object,
        default: () => {},
    },
});

const {
    extraData,
} = toRefs(props);

/*
    Variables
*/
const { richTextResolver } = useStoryblokApi();
const { dayjs } = useDayJs();
const router = useRouter();
const route = useRoute();
const runtimeConfig = useRuntimeConfig();
const baseUrl = computed(() => runtimeConfig.public.BASE_URL);
const storyblokApi = useStoryblokApi();
const articlesOfAuthor = ref(null);
const pageSize = ref(2);
const { locale: currentLocale, defaultLocale } = useI18n();
const language = currentLocale.value === defaultLocale.value ? '' : currentLocale.value;

const getArticlesOfAuthor = async () => {
    try {
        const { data } = await storyblokApi.get('cdn/stories/', {
            starts_with: 'news/news-article',
            version: 'published',
            resolve_relations: 'News.department',
            filter_query: {
                author: {
                    in: extraData.value.uuid,
                },
            },
            page: 1,
            per_page: pageSize.value,
            sort_by: 'first_published_at:desc',
            language,
        });

        articlesOfAuthor.value = data.stories;
    } catch (e) {
        useSentryError(e);
    }
};

const loadMore = () => {
    pageSize.value += 2;
    getArticlesOfAuthor();
};

onMounted(() => {
    setTimeout(() => {
        getArticlesOfAuthor();
    }, 200);
});

const share = () => {
    // share url mobile
    if (navigator.share) {
        try {
            navigator.share({
                title: extraData.value.name,
                url: baseUrl + route.fullPath,
            });
        } catch (error) {
            useSentryError(error);
        }
    } else {
        // share url desktop
        window.location.href = `mailto:?subject=${extraData.value.name}&body=${extraData.value.name}%0D%0A%0D%0A${baseUrl.value + route.fullPath}`;
    }
};
</script>
<style lang="scss">
.layout-person {
    position: relative;
    background-color: var(--c-background)
}

.layout-person__hero-person {
    @include default-content-columns;

    margin-top: 78px;
    margin-bottom: 33px;

    @include mobile {
        margin-bottom: 40px;
    }
}

.layout-person__hero-person-back {
    display: flex;
    width: fit-content;
    align-items: center;
    margin-bottom: 18px;
    color: var(--c-petrol-4);
    column-gap: 10px;
    cursor: pointer;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--f-line-height--richtext);

    .ion-icon {
        transition: all 0.3s ease;
    }

    p {
        padding-top: 1px;
    }

    &:hover {
        .ion-icon {
            transform: translate(-5px, 0)
        }
    }
}

.layout-person__wrapper {
    @include default-content-columns;

    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    row-gap: 15px;

    @include mobile {
        flex-direction: column;
    }
}

.layout-person__content-type {
    margin-bottom: 10px;
    color: var(--c-petrol-4);
    font-family: var(--f-family--regular);
    font-size: var(--f-size--tag);
    line-height: var(--l-height--tag);
}

.layout-person__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: var(--f-family--bold);
    font-size: var(--f-size--h2);
}

.layout-person__content-name {
    color: var(--c-petrol-4);

}

.layout-person__content-title {
    margin-bottom: 20px;
    color: var(--c-petrol-1);
}

.layout-person__content-website {
    display: flex;
    width: fit-content;
    align-items: center;
    color: var(--c-petrol-4);
    column-gap: 25px;
    cursor: pointer;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
}

.layout-person__share {
    display: flex;
    column-gap: 52px;

    div {
        cursor: pointer;
    }
}

.layout-person__biografie {
    margin-bottom: 80px;

    p {
        color: var(--c-petrol-1);
    }
}

.layout-person__biografie-title {
    margin-bottom: 30px;
    color: var(--c-petrol-1);
    font-family: var(--f-family--regular);
    font-size: var(--f-size--h3);
}

.layout-person__more-articles-grid {
    @include grid-layout(10);

    margin-bottom: 52px;
    row-gap: var(--grid-gutter);

    @include mobile {
        display: flex;
    }
}

.layout-person__more-articles-title {
    margin-bottom: 20px;
    color: var(--c-petrol-1);
    font-family: var(--f-family--bold);
    font-size: var(--f-size--h2);
}

.layout-person__more-article-icon {
    transition: all 0.3s ease;
}

.layout-person__more-article-image {
    overflow: hidden;
    height: 260px;

    .atom-image {
        transition: all 0.3s ease;
    }
}

.layout-person__more-article {
    display: flex;
    height: 100%;
    flex-direction: column;
    grid-column: span 5;
    row-gap: 21px;

    &:hover {
        .layout-person__more-article-icon {
            transform: translate(5px, 0)
        }

        .atom-image {
            transform: scale(1.05);
        }
    }
}

.layout-person__more-article-title {
    font-family: var(--f-family--black);
    font-size: var(--f-size--h4);
    line-height: var(--l-height--h4);
}

.layout-person__more-article-info {
    color: var(--c-petrol-1);
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--f-line-height--richtext);
}

.layout-person__more-button {
    max-width: 240px;
    align-self: end;
    padding: 13px 62px;
    margin-bottom: 50px;

    &.isHidden {
        display: none;
    }
}
</style>
